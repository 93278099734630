import Grid from "@components/Grid/Grid";
import { themr } from "@friendsofreactjs/react-css-themr";
import styles from "./C261_Contact.module.scss";
import ContactDetails, { ContactDetailsProps } from "./C261_ContactDetails";
import ContactDetailsExtended from "./C261_ContactDetailsExtended";
import LinkedInContactDetails, {
  ContactLinkedInProps,
} from "./C261_LinkedInContactDetails";

export interface ContactProps {
  title: string;
  isExtended: boolean;
  description: boolean;
  contactLinkedInDetails: ContactLinkedInProps[];
  contactsExtended: {
    contactDetails: ContactDetailsProps;
    contactLinkedInDetails: ContactLinkedInProps;
  }[];
  contactDetails: ContactDetailsProps[];
}

export const Contact = (props: { content: ContactProps; theme?: any }) => {
  const { content, theme } = props;
  const {
    title,
    isExtended,
    description,
    contactLinkedInDetails,
    contactsExtended,
    contactDetails,
  } = content;

  /* QA test condition - Once passed QA this consition can be removed */
  if (process.env.NEXT_PUBLIC_URL !== "https://www.london.edu") {
    console.log("C261_Contact component data: ", content);
  }
  /* end QA test condition */

  return (
    <div
      className={`component ${theme.contact} ${
        isExtended && theme["contact-details-extended"]
      }`}
    >
      <div className="wrapper">
        <div className={theme["header-section"]}>
          <Grid row>
            <Grid column sm={12} lg={5}>
              <h4>{title}</h4>
            </Grid>
            <Grid column sm={12} lg={7}>
              <p>{description}</p>
            </Grid>
          </Grid>
        </div>
        <div className={theme.content}>
          <>
            {isExtended ? (
              <ContactDetailsExtended
                isExtended={isExtended}
                theme={theme}
                contactsExtended={contactsExtended}
              />
            ) : (
              <Grid row>
                <Grid column sm={12} lg={5}>
                  <div className={theme.base6_tp12}>
                    <Grid row>
                      {contactLinkedInDetails &&
                        contactLinkedInDetails.map((contactDetail, index) => {
                          return (
                            <LinkedInContactDetails
                              theme={theme}
                              key={`${contactDetail.title}${index}`}
                              {...contactDetail}
                            />
                          );
                        })}
                    </Grid>
                  </div>
                </Grid>
                <Grid column sm={12} lg={7}>
                  <div className={theme["contact-details"]}>
                    <Grid row>
                      {contactDetails &&
                        contactDetails.map((contactDetail, index) => {
                          return (
                            <ContactDetails
                              theme={theme}
                              key={`${contactDetail.title}-${index}`}
                              {...contactDetail}
                            />
                          );
                        })}
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            )}
          </>
        </div>
      </div>
    </div>
  );
};

export default themr("Contact", styles)(Contact);
