import Grid from "@components/Grid/Grid";
import styles from "@components/InteractApplicationConfirmation/InteractApplicationConfirmation.module.scss";
import { themr } from "@friendsofreactjs/react-css-themr";
import richTextify from "@utilities/richtext";
import clsx from "clsx";
import { useEffect, useState } from "react";

export interface InteractApplicationProps {
  successTransactionMessage: string;
  failureTransactionMessage: string;
}

export const InteractApplicationConfirmation = (props: {
  content: InteractApplicationProps;
  theme?: any;
}) => {
  const { content, theme } = props;
  const { successTransactionMessage, failureTransactionMessage } = content;

  const [responseMessage, setResponseMessage] = useState("");

  const isWindow = typeof window === "object";
  const urlParams = isWindow
    ? new URLSearchParams(window.location.search)
    : null;
  const transactionReference = urlParams?.get("reference") || "";

  useEffect(() => {
    const fetchTransactionStatus = async () => {
      try {
        const response = await fetch(
          "/.netlify/functions/userTransactionStatus",
          {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },

            body: transactionReference,
          }
        );
        const responseJsonData = await response.json();

        console.log("responseJsonData", responseJsonData);

        if (response.ok && responseJsonData.status === "CHECKOUT") {
          setResponseMessage(successTransactionMessage);
        } else {
          setResponseMessage(failureTransactionMessage);
          const error = await response.json();
          console.log(error);
        }
      } catch (e) {
        console.error(e);
      }
    };

    fetchTransactionStatus();
  }, []);

  return (
    <div className="wrapper">
      <Grid row>
        <Grid column sm={12} md={12}>
          <div
            className={clsx(
              theme.component,
              "component",
              theme["interact-confirmation"]
            )}
          >
            {richTextify(responseMessage)}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default themr(
  "InteractApplicationConfirmation",
  styles
)(InteractApplicationConfirmation);
